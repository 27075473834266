import React from "react"
import { graphql } from "gatsby"
import Section from "../components/Section"
import Layout from "../components/Layout"
import Body from "../components/JobDescription"

export default function JobOpening({
  data: {
    markdownRemark: {
      html,
      frontmatter: { name, category },
    },
  },
}) {
  const renderedBody = <Body html={html} />
  return (
    <Layout>
      <Section
        title={name}
        subtitle={category}
        collapse={false}
        belowSection={renderedBody}
      ></Section>
    </Layout>
  )
}

export const query = graphql`
  query JobOpeningQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        category
      }
    }
  }
`
